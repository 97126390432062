/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React from "react";
import { Link } from "@react-navigation/web";

import './css/WETTable-shared.css'

/* COLUMN FUNCTIONALITY:

  -Columns can use <Link> to link to other pages, you do this by passing WETTable addHref prop
    -useHref: true
    -colForLink: column index number you want to be used for the link
    -colForData: column index to be used for the Link data

  -Columns can also be used to execute a function that you pass in, also using addHref prop
    -useHref: true
    -clickFunction: function to execute when clicked
    -colForLink: column index number you want to be used for the <a> that will execute the function
    -colForData: column index whose value/data will be passed to the click
*/

export default class WETTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: props.searching !== undefined ? props.searching : "true",
      pageLength: props.pageLength ? props.pageLength : 10,
      className: props.className
        ? "wb-tables table " + props.className
        : "wb-tables table table-striped table-hover",
      ordering: props.ordering !== undefined ? props.ordering : "true",
    };

    this.renderData = this.renderData.bind(this);
  }

  componentDidMount() {
    //Remove the default 'hide' className once loaded
    // this just hides the entire table until it's ready, without it you can get weird overflow on small screens
    const tableId = this.props.tableId;
    window.$(`#${tableId}`).on('init.dt', function () {
      document.getElementById(tableId)?.classList.remove('hide')
    })

    // Initialize the WET plugin(s)
    window.wb?.add(".wb-tables");
  }

  renderData( dataObj, columnIndex, rowIndex, row ) {
    // * You can include extra hidden columns by making header file shorter (good for including hidden column data for filtering)
    if(columnIndex > this.props.headers.length - 1)
      return <></>

    const href = this.props?.addHref;
    return (
      <td key={rowIndex + "_" + columnIndex}>
        {/* Add link to specified column (if enabled) */}
        {/* TODO: routeName should just be part of the "href" object with colForLink and useHref */}

        {/* Format column: regular, function-on-click, Link */}
        { columnIndex !== href?.colForLink ?
            // Regular column
            <span>{dataObj}</span>
            :
            // Function column or Link column
            (href?.clickFunction ?
              <a href={`#${row[href.colForData]}`} 
                onClick={(event) => {
                  event.preventDefault()
                  href.clickFunction(row[href.colForData])
                }}
              >
                  {dataObj}
              </a>
              :
              <Link routeName={this.props.routeName} params={{ dataId: row[href.colForData] }}> 
                <span>{dataObj}</span>dude
              </Link>
            )

        }
      </td>
    );
  }

  render() {
    const rowIdIndex = this.props.rowId;
    
    return (
      <div className="data-table-wrapper">
        <table
          className={`${this.state.className} hide`}
          data-page-length={this.state.pageLength}
          data-ordering={this.state.ordering}
          data-searching={this.state.searching}
          ref={(el) => (this.el = el)}
          id={this.props.tableId}
        >
          <thead>
            <tr>
              {this.props.headers?.map(function (header, index) {
                return <th key={"header_" + index}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {this.props.rows ? (
              this.props.rows.map(function (row, rowIndex) {
                const idAttributeValue = rowIdIndex !== undefined ? row[rowIdIndex] : null;
                return (
                  // row ID is useful when filtering to get back an array of the IDs
                  <tr key={rowIndex} id={idAttributeValue} > 
                    {row.map(
                      (column, index) => 
                        this.renderData( column, index, rowIndex, row ),
                      this
                    )}
                  </tr>
                );
              }, this)
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
