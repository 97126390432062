/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


export const NON_PRINT_CHAR = '\u200C';

// WETTable function to set a custom sort on a table so that blank values will not show up first on a sort
// * to work properly, any blank airport code should initially be set to the NON_PRINT_CHAR
// * run it after table has been initialized e.g. inside of a table.on('init.dt', ...) 
export const showBlanksLast = (tableId, colNum) => {
    const table = window.$('#' + tableId);

    //Wait for table to initialize
    const dataTable = table.DataTable();

    //Pre-draw event to change column values before rendering
    table.on('preDraw.dt', (e, settings) => {
        let order = settings.aaSorting;
        var sortedColumnIndex = order[0][0]; // index of the sorted column
        var sortedDirection = order[0][1]; // 'asc' or 'desc'

        //Customize blank codes based on sort direction to ensure they always come last
        const ASC = `<span>${NON_PRINT_CHAR}</span>` // non-printable/invisible character
        const DESC = '<span></span>'

        if (sortedColumnIndex === colNum) {
            dataTable.rows().every(function() {
                let data = this.data();
                if (data[colNum] === ASC || data[colNum] === DESC ) {
                    data[colNum] = sortedDirection === 'asc' ? DESC : ASC; //so this actually seems like it should be reversed,  but for some reason this works, i'm not sure why...
                    this.data(data); // Update the row data
                    // console.debug(`'${sortedDirection}' ${sortedDirection === 'asc'}: ${data[colNum]}, ${sortedDirection === 'asc' ? '(blank)' : '(non-printable char)'}`);
                }
            });
        }
    });
}