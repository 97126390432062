/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import WETTable from "../../components/WETTable-shared";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../components/AppContext-shared";
import { isExternal, domId } from "../../utils/miscUtils-shared";
import { categoryIcons } from "./serviceData-shared";

import './services-shared.css';

const CBSA_URL_PREFX = 'https://cbsa-asfc.gc.ca'

const Services = ({ serviceTabLinkClicked }) => {
    const { services, serviceCategories, messages } = useContext(AppContext);
    const [ tableData, setTableData ] = useState([])

    //Generate service table data
    useEffect(() => {
        const serviceList = []; 
        for (const [key, value] of services) {
            const catObj = serviceCategories.get(+value.category);
            
            serviceList.push([
                value.name, 
                //Category (include icons for small screens)
                <>
                    <span className="display-full" title={catObj?.long}>
                        {catObj?.short}
                    </span>
                    <span className="display-short" title={catObj}>
                        <i className={`fa-solid fa-lg ${categoryIcons[catObj?.number] ?? categoryIcons.none}`}></i>
                    </span>
                </>,
                //Description + URL (if available)
                <>
                    {value.description
                        ?.split('\n')
                        ?.map((line, index) => 
                            <span key={`${value.number}-${index}`}>
                                {index > 0 && <br/>}{line}
                            </span>
                        )
                    }
                    {value.url === 'None' ? '' :
                        <a href={CBSA_URL_PREFX + value.url} target="_blank" className="service-link" title={value.name}>
                            <i className="fa-solid fa-arrow-up-right-from-square"/>
                        </a>
                    }    
                </>,                  
                key, //hidden
            ]);
        }
        setTableData(serviceList);
    }, [])

    //Custom header for the table to allow for responsive design
    const customHeader = [
        messages['label.name'], 
        <> 
            {/* Categories */}
            <span className="display-full">{messages['label.category']}</span> 
            <span className="display-short">{messages['label.category.short']}</span> 
        </>,
        messages['label.description'], 
    ]

    useEffect(() => {
        //Actions to perform after the table is initialized
        //TODO: look for other tables that could use this listener
        window.$('#servicesTable').on('init.dt', function () {
            window.$('#servicesTable').DataTable().order([1, 'asc']).draw(); //sort table by service name
            domId('find-office')?.scrollIntoView({ behavior: 'smooth'}) //scroll page for optimal viewing
        })
    },[])

    return (
        <div className="panel-body">
            <div className="row profile">
                <div className="col-md-12">
                    {/* <h2>List of Services</h2> */}
                    {
                        <WETTable
                            headers={customHeader}
                            rows={tableData}
                            addHref={{ 
                                useHref: true,
                                clickFunction: serviceTabLinkClicked, //Function to switch tab back to region/province and show with selected service filtered
                                colForLink: 0, 
                                colForData: 3,
                            }}
                            routeName={ isExternal() ? "Region" : "Service" }
                            tableId="servicesTable"
                        ></WETTable>
                    }
                </div>
            </div>
        </div>
    );
}

export default Services;