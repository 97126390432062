/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


// * Unofficial Icon mapping for the service categories using font-awesome icons

export const categoryIcons = {
    1: 'fa-plane', //AIR
    2: 'fa-car', //LAND
    3: 'fa-ship', //MARINE
    4: 'fa-envelopes-bulk', //POSTAL
    5: 'fa-train', //RAIL
    6: 'fa-key', //LIMITED
    7: 'fa-tower-broadcast', //TELECOM
    8: 'fa-briefcase', //COMMERCIAL
    9: 'fa-passport', //IMMIGRATION
    10: 'fa-ellipsis', //OTHER
    
    none: 'fa-question',
};
