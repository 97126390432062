/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React, { useRef, useEffect, useState, useContext } from "react";
import AppContext from "../../components/AppContext-shared";
import Office from "../Office";
import { domId } from "../../utils/miscUtils-shared";

import "./officeDialog-shared.css";

const OfficeDialog = ({currentOffice, currentOfficeAirport}) => {
    const { activeOffice, updateActiveOffice } = useContext(AppContext);
    const officeDialogRef = useRef();
    const [headerDetails, setHeaderDetails] = useState(null);

    const closeOfficeDialog = () => {
		officeDialogRef.current.close();
		updateActiveOffice(null);
        setHeaderDetails(null);
	};

    //Show office modal when office is set
    useEffect(() => {
        if (activeOffice) {
            officeDialogRef.current.showModal();
        }
    }, [activeOffice]);

    //Scroll listener to shrink the header for mobile devices
    const checkScrollAtTop = () => {
        const header = domId('office-modal-header')
        if(domId('office-body').scrollTop !== 0) 
            header.classList.add('header-shrink')
        else header.classList.remove('header-shrink')
    }

    //TODO: Click outside closes office - not working right now
    /*const handleClickOutside = (event) => {
        if ( officeDialogRef.current?.open && !domId('office-dialog-container').contains(event.target) ) {
            closeOfficeDialog();
        }
    };
    document.addEventListener('click', handleClickOutside);*/

	//Office dialog listeners
	useEffect(() => {
        if (activeOffice) {
            officeDialogRef.current.showModal();
            // Disable scrolling on the body
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling on the body
            document.body.style.overflow = 'auto';
        }
		//Close listener
		const currentDialogRef = officeDialogRef.current;
		currentDialogRef.addEventListener('close', () => { closeOfficeDialog(); });

		//Click outside listener
		const handleClickOutside = (event) => {
			if ( officeDialogRef.current?.open && !domId('office-dialog-container').contains(event.target) ) {
				// TODO: disabling for now because it doesn't play nicely with edit screens, uncomment it when you have a chance to fix it
				// closeOfficeDialog();
			}
		};
		document.addEventListener('click', handleClickOutside);

        //Scroll listener to shrink the header for mobile devices
        const dialog = domId('office-body');
        dialog.addEventListener('scroll', checkScrollAtTop, { passive: true });

		return () => {
			currentDialogRef.removeEventListener('close', closeOfficeDialog);
			document.removeEventListener('click', handleClickOutside);
        dialog.removeEventListener('scroll', checkScrollAtTop);
		};
	}, [activeOffice]);

    return (
        <dialog id="officeDialog" ref={officeDialogRef} className="overlay-def">
            <div id="office-dialog-container">
                {/* Header */}
                <header id="office-modal-header" className="header">
                    <div className="office-header">
                        {currentOffice?.name}
                        {currentOfficeAirport?.show && ` - ${currentOfficeAirport.code} `}
                        {headerDetails} 
                    </div>
                    <div id="office-alias">{currentOffice?.alias}</div>
                    <button id="office-close-button" aria-label="Close office page" onClick={() => { closeOfficeDialog(); }} >
                        <i className="fa-solid fa-xmark fa-lg" />
                    </button>
                </header>

                {/* Body */}
                <div id="office-body" className="modal-body">
                    {activeOffice && <Office activeOfficeId={activeOffice} setHeaderDetails={setHeaderDetails}/> }
                </div>
            </div>            
        </dialog>
    )
}

export default OfficeDialog;
