// Import of pages in the app
import HomeScreen from "./Home/index-shared";

/**
 * All of the scenes (pages) in the app (or any app you want to make a <Link/> to)
 * for use by react-navigation/core > SwitchRouter
 */
export const scenes = {
  Home: {
    screen: HomeScreen,
    path: "index.html",
  }
};
