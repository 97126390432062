/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React from "react";
import Banner from "./Banner-shared";
import MainMenu from "./MainMenu";
import BreadCrumbs from "./BreadCrumbs-shared";
import { FormattedMessage } from "react-intl";

function Header(props) {
  return (
    <>
      <nav>
        <ul id="wb-tphp">
          <li className="wb-slc">
            <a className="wb-sl" href="#wb-cont">
              <FormattedMessage id="wet.skipToMainContent" />
            </a>
          </li>
          <li className="wb-slc visible-sm visible-md visible-lg">
            <a className="wb-sl" href="#wb-info">
              <FormattedMessage id="wet.skipToAboutThisSite" />
            </a>
          </li>
        </ul>
      </nav>
      <header role="banner">
        <Banner currentMenuItem={props.currentMenuItem} />
        <MainMenu currentMenuItem={props.currentMenuItem} />
        {/* <BreadCrumbs
          breadcrumb={props.breadcrumb}
          breadcrumbTitle={props.breadcrumbTitle}
          pageTitle={props.pageTitle}
        /> */}
      </header>
    </>
  );
}

export default Header;
