import React, { useContext, useState, useEffect } from "react";
import { Link } from "@react-navigation/web";
import { FormattedMessage } from "react-intl";

import axios from "axios";
import AppContext from "../../components/AppContext-shared";
import BingMap from "../../components/BingMap-shared";
import Spinner from "../../components/Spinner-shared";
import DisplayParsedText from "../../components/HTMLLink-shared";
import { categoryIcons } from "../Services/serviceData-shared";
import RequestTimeout from "../../components/RequestTimeout-shared"
import ErrorOverlay from "../../components/ErrorOverlay-shared";
import CustomTooltip from "../../components/CustomTooltip-shared";

import { isEnglish, isEmpty, currentLang, currentLangCamelcase, sortArrayAlphabetically } from "../../utils/miscUtils-shared";

import './office-shared.css'

const Office = ({ navigation, activeOfficeId }) => {
  const { offices, districts, regions, services, serviceCategories, messages } = useContext(AppContext);
  const [office, setOffice] = useState(null);
  const [serviceArray, setServiceArray] = useState(null);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [showErrorOverlay, setShowErrorOverlay] = useState(false);

  const officeId = +activeOfficeId ?? +navigation?.state?.params?.dataId;
  const apiURL = `office?id=${officeId}`;
  const reportErrorsEmail = `
    mailto:${messages["label.office.reportErrors.email.address"]}
    ?subject=${messages["label.office.reportErrors.email.subject"]}
    &body=${messages["menu.office"]}: ${office?.name ?? ""} (${officeId})
  `;
  const CBSA_URL_PREFX = 'https://cbsa-asfc.gc.ca';

  const getOfficedetails = async () => {
    setShowLoadingSpinner(true);
    let response;
    const existingOffice = offices?.get(officeId);
    try {
      const params = {
        params: {
          standardCsn: JSON.stringify(existingOffice.standardCsn),
          lang: currentLang(),
        }
      }

      response = await RequestTimeout(axios.get(window.env["api-url-prefix"] + apiURL, params), 50000);;
      const office = { ...existingOffice, ...response?.data };

      //Figure out what service categories there are among the office's services
      const categorySet = new Set();
      office?.services?.forEach(serviceNum => {
        categorySet.add(services.get(serviceNum)?.category);
      })
      setServiceArray(Array.from(categorySet).sort((a, b) => a - b)) //sort - primarily so that "other (10)" will come last

      setOffice(office);
    } catch (error) {
      console.error("error", error);
      setShowErrorOverlay(true);
    } finally {
      setShowLoadingSpinner(false);
    }
  };

  useEffect(() => {
    getOfficedetails();
  }, []);

  // Convenience vars for the JSX below
  const noteType = office?.suspended ? 'warning' : 'info'

  const NoneContainer = () => (
    <div className="listing-container" >
      <span className="col-md-9 no-padding">
        <span><FormattedMessage id="label.none" /></span>
      </span>
    </div>
  )

  return (
    <>
      <Spinner id="office-spinner" show={showLoadingSpinner} />
      <ErrorOverlay id="error-overlay" show={showErrorOverlay} />
      <div>
        {/* REGION / DISTRICT */}
        <h4 id="region-district">{regions.get(office?.regionNumber)?.name} - {districts.get(office?.districtNumber)?.name}</h4>

        {/* ADDRESS */}
        <div id="addresses" className="row">
          <div className="col-md-11">
            {office?.address ? sortArrayAlphabetically(office.address, 'type', true).map(({ active, cityEn, cityFr, postalCode, province, streetEn1, streetEn2, streetFr1, streetFr2, type }) => {
              // TODO: implement "geo" link for mobile browsers
              const address = `
                    ${isEnglish() ? streetEn1 : streetFr1}, 
                    ${streetEn2 ? (isEnglish() ? `${streetEn2}, ` : `${streetFr2}, `) : ''}
                    ${isEnglish() ? cityEn : cityFr}, 
                    ${province}, ${postalCode}
                  `;
              const directionsUrlPrefix = 'https://www.google.com/maps/dir/?api=1&destination='; //TODO: this should probably be in a config

              return (active ?
                <div className="address-listing" key={`address-${type}`}>
                  <b>{messages['label.address.' + type.toLowerCase()]}</b>: &nbsp;
                  <span>{address}</span>
                  {/* Directions arrow */}
                  {type !== 'MAILING' &&
                    <button className="directions fa-solid fa-location-arrow fa-lg" title={messages['label.directions']}
                      onClick={() => { window.open(`${directionsUrlPrefix}${office.latitude},${office.longitude}`, '_blank') }}
                    />
                  }
                </div>
                : <></>
              )
            }
            )
              :
              <>{messages["label.address.none"]}</>
            }
          </div>
        </div>

        {/* NOTES / SUSPENDED */}
        {office?.notes && (
          <div>
            <section>
              {!isEmpty(office?.notes) &&
                <div className={`alert alert-${noteType}`} id={`alert-${noteType}`}>
                  <h4>
                    <FormattedMessage id="alert.office.note" />&nbsp;&nbsp;
                  </h4>
                  <div>
                    <span>
                      {office?.suspended === true && <h4>{messages['label.office.note.suspended']}</h4>}
                    </span>
                    <div className="note-div line-break-support">{<DisplayParsedText text={office.notes} />}</div>
                  </div>
                </div>
              }
            </section>
          </div>
        )}

        {/* INFO TABLE */}
        <div className="row profile">
          <div className="col-md-12">
            {office && (
              <>
                <table id="officeTable" className="table table-bordered table-accent dorb-table">
                  <tbody className="office-table">
                    {/* PHONE NUMBERS */}
                    <tr>
                      <th scope="row">
                        <FormattedMessage id="label.phone" /> &nbsp;&nbsp;
                      </th>
                      <td colSpan="2" className="table-content">
                        {/* Generic number */}
                        <div className={`listing-container ${office.phone ? 'contact-info' : ''}`}>
                          <span className="col-md-4 listing-label">{messages['label.office.contact.borderInfoLine']}</span>
                          <span className="col-md-8">{messages['label.office.contact.borderInfoLine.number']}</span>
                        </div>
                        {/* Site numbers */}
                        {office.phone?.map((phone, index) => (
                          <div key={`phone-${index}`} className="row row-entries">
                            <span className="col-md-4 col-sm-4 col-xs-5 listing-label"><b>{`${phone[`label` + currentLangCamelcase()]}`}</b>: </span>
                            <span className="col-md-8 col-sm-8 col-xs-7">{phone.number || phone.value}</span>
                          </div>
                        ))}
                      </td>
                    </tr>

                    {/* EMAILS */}
                    <tr>
                      <th scope="row">
                        <FormattedMessage id="label.office.emails" /> &nbsp;&nbsp;
                      </th>
                      <td colSpan="2" className="table-content">
                        {/* Generic contact form */}
                        <div className={`listing-container ${office.email ? 'contact-info' : ''}`}>
                        {/* <div className="contact-info listing-container"> */}
                          <span className="col-md-4 listing-label">{messages['label.office.contact.contactUs']}</span>
                          <span className="col-md-8">
                            <FormattedMessage id="label.office.contact.contactUs.text" values={{
                              a: chunks =>
                                <a href={messages['label.office.contact.contactUs.url']} target="_blank" rel="noopener noreferrer">
                                  {chunks}
                                </a>
                            }} />
                          </span>
                        </div>
                        {/* Site emails */}
                        {office.email?.map((email, index) => (
                          <div key={`email-${index}`} className="row row-entries">
                            <span className="col-md-4 col-sm-4 col-xs-5 listing-label"><b>{email[`label${currentLangCamelcase()}`]}</b>: </span>
                            <span className="col-md-8 col-sm-8 col-xs-7 break-word"><a href={`mailto:${email.value}`}>{email.value}</a></span>
                          </div>
                        ))}
                      </td>
                    </tr>
                    {/* HOURS */}
                    <tr>
                      <th scope="row">
                        <FormattedMessage id="label.office.hours" /> &nbsp;&nbsp;
                      </th>
                      {/* Hour boxes */}
                      <td colSpan="2" className="hours-container">
                        {!office?.hours && <NoneContainer/> }
                        {office?.hours?.map((hours, index) => (
                          <div key={`hours-${index}`} className={`hours col-md-6`} >
                            <div className="hours-label">
                              {`${hours['label' + currentLangCamelcase()]}`}
                            </div>
                            <div className="hours-description line-break-support">
                              {<DisplayParsedText text={hours.descriptionEn ? `${hours[`description` + currentLangCamelcase()]}` : ""} />}
                            </div>
                            <div className="hours-days-container">
                              {hours.officeHours?.map((hoursDay, index) => (
                                <div key={`hoursDays-${index}`} className="hours-day">
                                  <span className="col-md-7">{`${hoursDay[`label` + currentLangCamelcase()]}`}</span>
                                  {hoursDay.inputType === 'time' ?
                                    <span className="col-md-5">{hoursDay.start} - {hoursDay.end}</span> :
                                    <span className="col-md-5">{`${hoursDay[`custom` + currentLangCamelcase()]}`}</span>
                                  }

                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </td>
                    </tr>

                    {/* SERVICES */}
                    <tr>
                      <th scope="row">
                        <FormattedMessage id="label.office.services" /> &nbsp;&nbsp;
                      </th>
                      <td colSpan="2" className="table-content services-container">
                        {serviceArray?.length === 0 && <NoneContainer/> }
                        {serviceArray?.map((categoryNum, categoryIndex) => {
                          return (
                            <div className="listing-container service-cat-container">
                              {/* Category */}
                              <span key={categoryIndex} className="col-md-4 col-sm-4 category-container">
                                <i className={`service-icon fa-solid fa-lg ${categoryIcons[categoryNum]}`}></i>
                                <span>{serviceCategories.get(categoryNum)?.short}</span>
                              </span>

                              {/* Services */}
                              <div className="col-md-8 col-sm-8">
                                {office?.services?.map(serviceNum => services.get(serviceNum)).sort((a, b) => a.name.localeCompare(b.name)).map((service, index) => {
                                  const serviceNum = service?.number; //
                                  const aoe = office.airportOfEntry;

                                  return +services?.get(serviceNum)?.category === categoryNum &&
                                    <div key={`services-${index}`}>
                                      <span>
                                        {
                                          service?.url === 'None' ? service?.name + ' ' :
                                            <a href={CBSA_URL_PREFX + service?.url} target="_blank" rel="noreferrer" className="service-link">
                                              {service.name}&nbsp;
                                            </a>
                                        }
                                        {/* AOE special display - include clearance limit */}
                                        {`(${service.code}${service.code === 'AOE' && aoe?.clearanceLimit ? `/${aoe?.clearanceLimit})` : ')'}`}
                                      </span>
                                      &nbsp;&nbsp;
                                      {/* Tooltip info */}
                                      <CustomTooltip text={service.description} >
                                        <span className="fa-solid fa-circle-info" />
                                      </CustomTooltip>
                                      {/* AOE - special display for AOE properties */}
                                      {service.code === 'AOE' && (aoe?.clearanceLimit || aoe?.stagedOffloadingNumber) &&
                                        <>
                                          <div id="aoe-child">
                                            {office?.airportOfEntry?.clearanceLimit &&
                                              <div className="service-aoe-info">
                                                <span>{messages["label.office.services.aoe.clearanceLimit"]}: </span>
                                                <span>{aoe.clearanceLimit}</span>
                                              </div>
                                            }
                                            {office?.airportOfEntry?.stagedOffloadingNumber &&
                                              <div className="service-aoe-info">
                                                <span>{messages["label.office.services.aoe.stagedOffloadingNumber"]}: </span>
                                                <span>{office?.airportOfEntry.stagedOffloadingNumber}</span>
                                              </div>
                                            }
                                          </div>
                                        </>
                                      }
                                    </div>
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </td>
                    </tr>

                    {/* LANGUAGES */}
                    <tr>
                      <th scope="row">
                        <FormattedMessage id="label.office.languages" />
                      </th>
                      <td colSpan="2" className="table-content">
                        <span>
                          {office.languages?.en && (<>
                            <img src="./images/en.gif" alt={messages['label.office.languageEn']} />&nbsp;
                            <FormattedMessage id="label.office.languageEn" />&nbsp;&nbsp;
                          </>)}
                          {office.languages?.fr && (<>
                            <img src="./images/fr.gif" alt={messages['label.office.languageFr']} />&nbsp;
                            <FormattedMessage id="label.office.languageFr" />
                          </>)}
                        </span>
                        <span></span>
                      </td>
                    </tr>

                    {/* US PORT */}
                    {office?.usPort &&
                      <tr>
                        <th scope="row">
                          <FormattedMessage id="label.office.usPort" />
                        </th>
                        <td colSpan="2" className="table-content">
                          {office.usPort}
                          <a href={"https://www.cbp.gov/about/contact/ports"} target="_blank" className="service-link">
                            <i className="fa-solid fa-arrow-up-right-from-square" />
                          </a>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>

        {/* BING MAPS */}
        {office?.latitude && office?.longitude &&
          <div id="office-map-holder"> 
            <BingMap parentName="office" offices={[office]} zoomLevel={15} currentLang={currentLang()} infoBox={false}/>
          </div>
        }

        {/* Small note */}
        <div id="notes-errors">
          <FormattedMessage
            id="label.office.reportErrors"
            values={{ a: (chunks) => <a href={reportErrorsEmail}>{chunks}</a>, }}
          />
        </div>
        <br />
      </div>
    </>
  );
};

Office.path = "index.html";
Office.navigationOptions = {
  pageTitle: <FormattedMessage id="menu.office" />,
  breadcrumb: [
    <Link routeName="Home" params="en">
      <FormattedMessage id="home.title" />
    </Link>,
    <Link routeName="Region" params={{ dataId: '{regionId}' }}>
      <FormattedMessage id="menu.region" />
    </Link>,
  ],
  breadcrumbTitle: <FormattedMessage id="menu.office" />,
};

export default Office;
