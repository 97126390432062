import React, { useState, useEffect, useRef } from 'react';
import {useIntl, FormattedMessage} from 'react-intl';

export default function MainMenu(props) {
  const [menuExpanded, setMenuExpanded] = React.useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const menuRef = useRef(null);

  const focusedStyle = {
    outline: '2px solid #26374A',
    backgroundColor: 'white',
    color: 'black' 
  };
    
    function menuButtonClick(event) {
        setMenuExpanded(!menuExpanded);
    }

    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuExpanded(false);
      }
    }
  
    useEffect(() => {
      if (menuExpanded) {
        menuRef.current.focus();
      }
      else {
        document.addEventListener('mousedown', handleClickOutside);
      } 
    }, [menuExpanded]);
  
    function handleKeyDown(event) {
      const items = menuRef.current.querySelectorAll('a[role="menuitem"]');
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex + 1) % items.length);
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
      } else if (event.key === 'Escape') {
        setMenuExpanded(false);
      } else if (event.key === 'Tab') {
        setMenuExpanded(false);
      }
    }
  
    useEffect(() => {
      const items = menuRef.current.querySelectorAll('a[role="menuitem"]');
      if (focusedIndex >= 0 && focusedIndex < items.length) {
        items[focusedIndex].focus();
      }
    }, [focusedIndex]);
    
    return (
        <>
          <nav id="navtest" className="gcweb-menu wb-init" typeof="SiteNavigationElement">
            <div className="container">
              <h2 className="wb-inv"><FormattedMessage id="wet.topMenu.menu"/></h2>
              <button type="button" id="menuButton" aria-haspopup="true" aria-expanded={menuExpanded} onClick={menuButtonClick} onKeyDown={handleKeyDown}><FormattedMessage id="wet.topMenu.mainMenu" values={{span: chunks => <span className="wb-inv">{chunks} </span>}}/> <span className="expicon glyphicon glyphicon-chevron-down"></span></button>
              <ul role="menu" aria-orientation="vertical" ref={menuRef} tabIndex="-1" onKeyDown={handleKeyDown}>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.jobsAndTheWorkplace.link"})}style={focusedIndex === 0 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.jobsAndTheWorkplace"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.immigrationAndCitizenship.link"})}style={focusedIndex === 1 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.immigrationAndCitizenship"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.travelAndTourism.link"})}style={focusedIndex === 2 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.travelAndTourism"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.businessAndIndustry.link"})}style={focusedIndex === 3 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.businessAndIndustry"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.benefits.link"})}style={focusedIndex === 4 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.benefits"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.health.link"})}style={focusedIndex === 5 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.health"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.taxes.link"})}style={focusedIndex === 6 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.taxes"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.environmentAndNaturalResources.link"})}style={focusedIndex === 7 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.environmentAndNaturalResources"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.nationalSecurityAndDefence.link"})}style={focusedIndex === 8 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.nationalSecurityAndDefence"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.cultureHistoryAndSport.link"})}style={focusedIndex === 9 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.cultureHistoryAndSport"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.policingJusticeAndEmergencies.link"})}style={focusedIndex === 10 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.policingJusticeAndEmergencies"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.transportAndInfrastructure.link"})}style={focusedIndex === 11 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.transportAndInfrastructure"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.canadaAndTheWorld.link"})}style={focusedIndex === 12 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.canadaAndTheWorld"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.moneyAndFinances.link"})}style={focusedIndex === 13 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.moneyAndFinances"/></a></li>
                <li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.scienceAndInnovation.link"})}style={focusedIndex === 14 ? focusedStyle : {}}><FormattedMessage id="wet.topMenu.scienceAndInnovation"/></a></li>
              </ul>
            </div>
          </nav>
        </>
    );
}