import React, { useState, useEffect } from "react";
//navigation
import { createNavigator, SwitchRouter, SceneView, } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import { scenes } from "./scenes/Scenes";
import { currentLang } from "./utils/miscUtils-shared";
import RequestTimeout from "../src/components/RequestTimeout-shared"

//Internationalization and Localization
import { IntlProvider } from "react-intl";
import messages_en from "./translations/en-shared.json"; //TODO: review translation files and delete those we don't need
import messages_fr from "./translations/fr-shared.json";

//Template Components
import AppContext from "./components/AppContext-shared";
import Header from "./templates/Header-shared";
import DateModified from "./templates/DateModified-shared";
import Footer from "./templates/Footer";

import axios from "axios";

const TemplateView = (props) => {
  const [versionNumber] = useState(props.version);
  const [dateModified] = useState( !!props.dateModified ? Number(props.dateModified) : Date.now() );
  const [messages] = useState( currentLang() === "fr" ? messages_fr : messages_en );

  //Context vars
  const [regions, setRegions] = useState([]);
  const [offices, setOffices] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [activeOffice, setActiveOffice] = useState(); //for showing the office as a pop-up (from home component)
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  //Global alerts
  const { descriptors, navigation } = props;
  const activeKey = navigation?.state.routes[navigation?.state.index].key;
  const descriptor = !!descriptors && !!activeKey ? 
    descriptors[activeKey]
    : 
    {
      navigation: {},
      options: {},
      getComponent: () => {
        return "Home";
      },
    };

  const appContextValues = { 
    regions, 
    districts, 
    offices, 
    services, 
    serviceCategories, 
    provinces,
    activeOffice, updateActiveOffice, 
    messages,
    showErrorMessage
  };

  // Set the Active office and update url
  function updateActiveOffice(id) {
    
    let urlParams = new URLSearchParams(window.location.search);
    let isNull = (id === null || id === undefined || id === '')

    if(!isNull){
      // If id parameter exists set url to that id and set Active Office
      urlParams.set("id", id);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      setActiveOffice(id)
    } else{
      // If id parameter does not exist or is invalid delete the id param from url and set Active Office to null
      urlParams.delete("id");
      window.history.replaceState({}, '',`${window.location.pathname}?${urlParams}`);
      setActiveOffice(null)
    }

  }

  function officeIdExists(officesMap, id) {
    for (let [key] of officesMap.entries()){
      if (key === id){
        return true;
      }
    }
    return false;  
  }

  async function loadIntitialData() {
    try {
      const apiInitialLoadPath = `initial-load?lang=${currentLang()}`;
      const response = window.env.useMockData ? 
        window.env.testResponse
        : await RequestTimeout(axios.get(window.env["api-url-prefix"] + apiInitialLoadPath), 50000);

      setProvinces( new Map(response?.data?.provinces?.map((obj) => [obj.code, obj])) );
      setRegions( new Map(response?.data?.regions?.map((obj) => [obj.number, obj])) );
      setDistricts( new Map(response?.data?.districts?.map((obj) => [obj.number, obj])) );
      setServices( new Map(response?.data?.services?.map((obj) => [obj.number, obj])) );
      setServiceCategories(new Map(response?.data?.serviceCategories?.map((obj) => [obj.number, obj])));
      let officesMap = new Map(response?.data?.offices?.map((obj) => [+obj.number, obj]))
      setOffices( officesMap );

      // Handle id url parameter on first load
      let urlParams = new URLSearchParams(document.location.search);
      let idParam = urlParams.has("id") ? urlParams.get("id") : null;

      if(officeIdExists(officesMap, Number(idParam))){
        updateActiveOffice(idParam);
      } else{
        updateActiveOffice(null);
      }

      return response.data;
    } catch (error) {
      console.error("Error Retrieving Initial Data Load", error);
      setShowErrorMessage(true);
    }
  }

  useEffect(() => {
    loadIntitialData();
  }, []);


  return (
    <IntlProvider locale={currentLang()} messages={messages}>
      <Header
        pageTitle={descriptor.options.pageTitle}
        currentMenuItem={activeKey}
      />
      <main className="container" typeof="WebPageElement" property="mainContentOfPage" >
        <AppContext.Provider value={appContextValues}>
          {/* TITLE */}
          <h1 id="wb-cont" property="name">
            {messages['home.title']}
          </h1>
          {/* BODY */}
          <SceneView component={descriptor.getComponent()} navigation={descriptor.navigation} />
        </AppContext.Provider>
        <DateModified versionNumber={versionNumber} dateModified={dateModified} />
      </main>
      <Footer />
    </IntlProvider>
  );
};

//*important* react-navigation is primarily for native webapps written with react-native,
//we utilize the 'web-support' feature in regular react for a more consistent code base between website development and native app development
//for more info on how this works, see: https://reactnavigation.org/docs/3.x/web-support/
const AppNavigator = createNavigator(TemplateView, SwitchRouter(scenes), {});

const App = createBrowserApp(AppNavigator);
export default App;
export { TemplateView };
