/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import { renderToStaticMarkup } from 'react-dom/server';
import React, { useEffect, useState, useRef, useContext } from "react";
import AppContext from "./AppContext-shared";

import './css/BingMap-shared.css';

/**
 * ! NOTES
 * ! Props: expects an offices ARRAY
 * 
 *  Default view is to the whole country
 * 
 * TODO: when click on a pin, move the view to encompass the infobox
 * 
 */
const CANADA_COORDINATES = [60.7907886528436, -95.87072138507942];

window.sj_evt = undefined; //To suppress an issue cause by Bing Map when at maximum zoom: https://github.com/milespratt/bingmaps-react/issues/75

const BingMap = ({ zoomLevel, parentName, currentLang, infoBox, offices: propOffices }) => {
    const elementId = `myMap-${parentName}`
    let lang = 'en-CA';
    currentLang == 'en' ? lang = 'en-CA' : lang = 'fr-CA';

    const [loadingCheck, setLoadingCheck] = useState(false); //use to prevent actions until bing src is fully loaded
    const [bingOffices, setOffices] = useState(propOffices);

    const mapRef = useRef(null); //bing map object
    const infoboxRef = useRef(null); //bing map info box to show pin information

    const { offices, services, messages, updateActiveOffice } = useContext(AppContext);

    let clusterLayer;
    infoBox = infoBox ?? true;

    //Update map
    function loadMapInfo() {
        const map = mapRef.current;
        let pins = [];
        let pinLocations = [];

        //Set info for pins (location, name, id)
        bingOffices?.forEach((office) => {
            if (!office?.latitude || !office?.longitude) {
                return;
            }
            const location = new window.Microsoft.Maps.Location(office.latitude, office.longitude);
            pinLocations.push(location);

            const pin = new window.Microsoft.Maps.Pushpin(location, { 
                title: office.name,

                //License: Apache, free to use anywhere. Made by vaadin: https://github.com/vaadin/vaadin-icons
                //https://www.svgviewer.dev/s/478697/map-marker
                icon: '<svg width="32px" height="48px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">'
                +'<path fill="#cc9900" d="M8 0c-2.8 0-5 2.2-5 5s4 11 5 11c1 0 5-8.2 5-11s-2.2-5-5-5zM8 8c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path>'
                +'</svg>', 

                anchor: new window.Microsoft.Maps.Point(16, 36), //this will position the reverse drop so that the bottom is exactly on top of the location
            });
            pins.push(pin);

            //Use to set the infobox content when user clicks a pin in pushTest()
            pin.metadata = {
                title: office.name,
                officeId: office.number
            }

            //add click event handler to the pushpin
            if(infoBox) window.Microsoft.Maps.Events.addHandler(pin, 'click', pinClicked);
        })

        //Set view zoom to make sure all offices in view
        if (pinLocations.length > 1) {
            const bounds = window.Microsoft.Maps.LocationRect.fromLocations(pinLocations);
            map.setView({ bounds: bounds, padding: 22 }); //padding = extra space given to bounding box (22 is minimum => to fit saskatchewan)
        } else if (pinLocations.length == 0) {
            map.setView({ center: new window.Microsoft.Maps.Location(...CANADA_COORDINATES), zoom: 3 });
        } else {
            map.setView({ center: pinLocations[0], zoom: 15 });
        } //single location is just centered

        // Do this if you prefer to just have regular pins, otherwise clustering layer below will work
        // map.entities.clear();
        // map.entities.push(pins);  

        //! TODO: if 2 locs have the exact same coordinates they will NEVER break apart, this is an issue (probably best way to solve is to listen to zoom change and if it's at max, disable clustering)
        //Load Clustering module - groups pins when zoomed out
        window.Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
            map.layers.clear();
            clusterLayer = new window.Microsoft.Maps.ClusterLayer(pins, {
                clusteredPinCallback: createCustomClusterPushpins,
                //callback: createPushpinList
                gridSize: 44 //use to adjust clustering size, 45 is default
            });
            map.layers.insert(clusterLayer);
        });
    }

    function createCustomClusterPushpins(cluster) {
        //Create title for the cluster
        cluster.setOptions({
            //options like title or color for clusters
            color: '#004466'
        });

        //Add handler for the cluster click event
        window.Microsoft.Maps.Events.addHandler(cluster, 'click', pinClicked)
    }


    function pinClicked(e) {
        if (e.target?.containedPushpins) { //check if pin is a cluster of pins 
            const clusterList = [];
            for (let i = 0; i < e.target.containedPushpins.length; i++) {
                clusterList.push(
                    {
                        clusterPin: e.target.containedPushpins[i].metadata,
                        clusterOffice: offices.get(e.target.containedPushpins[i].metadata.officeId),
                        clusterTitleId: `bing-link-${e.target.containedPushpins[i].metadata.officeId}`
                    }
                )
            }

            const clusterBoxContent =
                <div>
                    <div className="bing-cluster-label">{messages['label.map.cluster.title']}:</div>
                    <ul>
                        {clusterList.map((clusterList) => {
                            return <>
                                <li>
                                    <a className="bing-cluster-title no-visited-link" id={clusterList.clusterTitleId} href="#" data-office-id={clusterList.clusterPin.officeId}>
                                        {clusterList.clusterPin.title}
                                    </a>
                                </li>
                            </>
                        })}
                    </ul>
                </div>

            infoboxRef.current.setOptions({
                location: e.target.getLocation(),
                description: renderToStaticMarkup(clusterBoxContent),
                maxHeight: 400,
                maxWidth: 400,
                visible: true
            });

            //Add listener
            clusterList.forEach((cluster) => {
                document.getElementById(cluster.clusterTitleId).addEventListener('click', (event) => {
                    event.preventDefault();
                    updateActiveOffice(event.target.dataset?.officeId);
                });
            });
        } else if (e.target?.metadata) {
            const pin = e.target.metadata;
            const office = offices.get(+pin.officeId);
            const titleId = `bing-link-${pin.officeId}`;

            //Content of info box
            const boxContent =
                <div>
                    <a className="bing-title" id={titleId} href="#" data-office-id={pin.officeId}>{pin.title}</a>
                    <div className="bing-service-label">{messages['label.office.services']}:</div>
                    {office.services ?
                        <ul>
                            {office.services?.map((serviceNum) => {
                                return <li key={serviceNum}>{services.get(serviceNum)?.name}</li>
                            })}
                        </ul>
                        :
                        <div>{messages['label.none']}</div>
                    }
                </div>

            infoboxRef.current.setOptions({
                location: e.target.getLocation(),
                description: renderToStaticMarkup(boxContent),
                maxHeight: 400,
                maxWidth: 400,
                visible: true
            });

            //Add listener
            document.getElementById(titleId).addEventListener('click', (event) => {
                event.preventDefault();
                updateActiveOffice(event.target.dataset?.officeId);
            });
        }
    }

    const defaultZoomLevel = window.innerWidth > 699 ? 3 : 2;

    //Runs after Bing scripts finish loading
    window.GetMap = function () {
        mapRef.current = new window.Microsoft.Maps.Map('#' + elementId, {
            center: window.Microsoft.Maps.Location(...CANADA_COORDINATES), //default country view
            zoom: zoomLevel ?? defaultZoomLevel,
            //disableStreetside: true, //Currently when using this, if the place you are trying to get the view doesn't have streetside data, it will throw GET bad request (400)
                                       //https://answers.microsoft.com/en-us/bing/forum/all/bing-birds-eye-view-gives-me-error-400-for-some/7772e051-e830-4b7c-bc1d-423efa4dd073
                                       //https://answers.microsoft.com/en-us/bing/forum/all/bing-streetside-api-returns-400-error-recently/e698be19-d0df-40b0-9dda-fadcfe0d0adc
            disableBirdseye: true, //disabling this since it is throwing TypeError: Cannot read properties of null (reading '0'), likely on Bing's side
            minZoom: defaultZoomLevel,
            // disableScrollWheelZoom: true,
            // navigationBarOrientation: true,
            navigationBarMode: window.Microsoft.Maps.NavigationBarMode.compact,
            maxBounds: new window.Microsoft.Maps.LocationRect.fromCorners(
                new window.Microsoft.Maps.Location(83.500000, -148.000000), // Northwest corner
                new window.Microsoft.Maps.Location(35.000000, -47.000000)   // Southeast corner
            ),
            showLocateMeButton: true
        });
        infoboxRef.current = new window.Microsoft.Maps.Infobox(mapRef.current?.getCenter(), {
            visible: false
        });
        infoboxRef.current.setMap(mapRef.current);

        setLoadingCheck(true);
    }

    //import external Bing Map API Script and check if map is loaded
    useEffect(() => {
        if (window?.Microsoft?.Maps) {
            //script is already loaded, resolve immediately
            window.GetMap();
            return;
        }

        const script = document.createElement('script');

        //External script URL | call GetMap when API finishes loading
        // TODO: handle expiry/invalid key properly. It will paint a banner across the map, catch the error and handle it
        script.src = `https://www.bing.com/api/maps/mapcontrol?callback=GetMap&setMkt=${lang}&setLang=${lang}&key=${window.env.map}`;
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.onerror = async () => { console.error('Error loading external BingMap Script') };

        document.head.appendChild(script);

        //clean up the script when component unmounts
        return () => {
            document.head.removeChild(script);
        }
    }, []);

    // loadingCheck: display initial map when Maps API has finally finished loading
    // bingOffices: update map whenever offices are updated
    useEffect(() => {
        if (loadingCheck) {
            loadMapInfo();
        }
    }, [loadingCheck, bingOffices])

    //Set office state when prop changes (need to separate from office useState to ensure changes are reflected correctly)
    useEffect(() => {
        setOffices(propOffices);
    }, [propOffices]);

    return (
        <div id={elementId} className="map-container"/>
    )
}

export default BingMap;