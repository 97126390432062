/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React from "react";

export default function DisplayParsedText({ text }) {
    return <div dangerouslySetInnerHTML={{ __html: parseTextForLinks(text) }} />;
}

const parseTextForLinks = (text) => {
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
    return text.replace(linkRegex, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');
  }
