/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React, { useState } from 'react'

//Function to wrap a promise with a timeout and handler
const RequestTimeout = (promise, timeoutMs) => {
    const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), timeoutMs)
    );
    
    return Promise.race([promise, timeoutPromise]);
}

export default RequestTimeout;