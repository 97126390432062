import React from "react"
import {useIntl, FormattedMessage} from 'react-intl';


function Footer(props) {
    return (
        <>
          <footer id="wb-info">
            <div className="landscape">
              <nav className="container wb-navcurr">
                <h2 className="wb-inv"><FormattedMessage id="wet.aboutGovernment"/></h2>
                <ul className="list-unstyled colcount-sm-2 colcount-md-3">
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.contactUs.link"})}><FormattedMessage id="wet.contactUs"/></a></li>
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.departmentsAndAgencies.link"})}><FormattedMessage id="wet.departmentsAndAgencies"/></a></li>
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.publicServiceAndMilitary.link"})}><FormattedMessage id="wet.publicServiceAndMilitary"/></a></li>
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.news.link"})}><FormattedMessage id="wet.news"/></a></li>
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.treatiesLawsAndRegulations.link"})}><FormattedMessage id="wet.treatiesLawsAndRegulations"/></a></li>
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.governmentWideReporting.link"})}><FormattedMessage id="wet.governmentWideReporting"/></a></li>
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.primeMinister.link"})}><FormattedMessage id="wet.primeMinister"/></a></li>
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.howGovernmentWorks.link"})}><FormattedMessage id="wet.howGovernmentWorks"/></a></li>
                  <li><a href={useIntl().formatMessage({id:"wet.appFooter.openGovernment.link"})}><FormattedMessage id="wet.openGovernment"/></a></li>
                </ul>
              </nav>
            </div>
            <div className="brand">
              <div className="container">
                <div className="row">
                  <nav className="col-md-9 col-lg-10 ftr-urlt-lnk">
                    <h2 className="wb-inv"><FormattedMessage id="wet.aboutThisSite"/></h2>
                    <ul>
                      <li><a href={useIntl().formatMessage({id:"wet.appFooter.socialMedia.link"})}><FormattedMessage id="wet.socialMedia"/></a></li>
                      <li><a href={useIntl().formatMessage({id:"wet.appFooter.mobileApplications.link"})}><FormattedMessage id="wet.mobileApplications"/></a></li>
                      <li><a href={useIntl().formatMessage({id:"wet.appFooter.aboutCanada_ca.link"})}><FormattedMessage id="wet.aboutCanada_ca"/></a></li>
                      <li><a href={useIntl().formatMessage({id:"wet.appFooter.termsAndConditions.link"})}><FormattedMessage id="wet.termsAndConditions"/></a></li>
                      <li><a href={useIntl().formatMessage({id:"wet.appFooter.privacy.link"})}><FormattedMessage id="wet.appFooter.privacy"/></a></li>
                    </ul>
                  </nav>
                  <div className="col-xs-6 visible-sm visible-xs tofpg"> <a href="#wb-cont"><FormattedMessage id="wet.topOfPage"/> <span className="glyphicon glyphicon-chevron-up"></span></a> </div>
                  <div className="col-xs-6 col-md-3 col-lg-2 text-right"> <img src="./GCWeb/assets/wmms-blk.svg" alt={useIntl().formatMessage({id:"wet.symbolOfTheGovernmentOfCanada"})}/> </div>
                </div>
              </div>
            </div>
          </footer>
        </>
    );
}

export default Footer;