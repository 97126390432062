/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React from "react";
import LanguageSelection from "./LanguageSelection-shared.js";
import { FormattedMessage, useIntl } from "react-intl";

function Banner(props) {
  return (
    <div id="wb-bnr" className="container">
      <LanguageSelection />
      <div className="row">
        <div
          className="brand col-xs-5 col-md-4"
          property="publisher"
          typeof="GovernmentOrganization"
        >
          <a
            href={"https://www.canada.ca/{lang}.html".replace(
              "{lang}",
              document.documentElement.lang
            )}
            property="url"
          >
            <img
              src={"./GCWeb/assets/sig-blk-{lang}.svg".replace(
                "{lang}",
                document.documentElement.lang
              )}
              alt=""
              property="logo"
              key="governmentOfCanadaTopLogo"
            />
            <span className="wb-inv" property="name">
              {" "}
              Government of Canada /{" "}
              <span lang="fr">Gouvernement du Canada</span>
            </span>
          </a>
          <meta property="areaServed" typeof="Country" content="Canada" />
          <link property="logo" href="./GCWeb/assets/wmms-blk.svg" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
